import React from "react";

interface InlineLinkProps {
  children: React.ReactNode;
  href: string;
}

const InlineLink = ({ children, href }: InlineLinkProps) => {
  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      className="group relative z-10 overflow-hidden no-underline"
    >
      <span className="absolute left-[8px] bottom-0 -z-10 h-[calc(100%-8px)] w-[calc(100%-8px)] transform bg-zinc-200 [transition:0.35s_cubic-bezier(0.25,_0.1,_0,_2.05)] group-hover:left-0 group-hover:-bottom-0 group-hover:h-full group-hover:w-full dark:bg-zinc-700" />
      {children}
    </a>
  );
};

export default InlineLink;

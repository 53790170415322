import React from "react";
import { projects } from "../data/ProjectList";
import ProjectCard from "./ProjectCard";

const ProjectShowcase = () => {
  return (
    <div className="flex flex-col items-center justify-between lg:flex-row">
      <div className="flex w-full flex-1 flex-row justify-start group text-4xl font-semibold lg:text-5xl ml-2">
        <h1>Projects 🚀</h1>
      </div>
      <div className="mt-4 grid flex-2 grid-cols-1 gap-4 lg:my-8 lg:gap-8">
        {projects
          .slice(0)
          .sort((a, b) => a.id - b.id)
          .slice(0, 3)
          .map((project) => (
            <ProjectCard key={project.id} project={project} />
          ))}
      </div>
    </div>
  );
};

export default ProjectShowcase;
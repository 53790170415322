import React from "react";
import { Link } from "react-router-dom";

interface SectionReturnLinkProps {
  heading: string;
  href: string;
}

const SectionReturnLink = ({ heading, href }: SectionReturnLinkProps) => {
  return (
    <Link
      className="group flex flex-row text-xl font-semibold lg:text-2xl"
      to={`/${href}`}
      reloadDocument
    >
      <span className="transition ease-in-out group-hover:-translate-x-2">
        {"<-"}
      </span>
      <h1 className="ml-2">{heading}</h1>
    </Link>
  );
};

export default SectionReturnLink;

import React from "react";
import {
  BsGithub,
  BsLinkedin,
  BsEnvelopeFill,
} from "react-icons/bs";
import { Link } from "react-router-dom";
import IconLink from "./IconLink";
import ThemeToggle from "./ThemeToggle";

const Footer = () => {
  return (
    <div className="pt-12 flex flex-col items-start justify-between gap-6 px-8 py-8 md:flex-row md:items-center md:gap-0 md:px-16 lg:px-24 xl:px-48 fhd:mx-auto fhd:w-full fhd:max-w-screen-2xl fhd:px-0">
      <div className="flex flex-row items-center justify-center">
        <ThemeToggle />
        <Link to="/" reloadDocument>
          <span className="ml-2 mr-1 text-base">Montgomery Bohde</span>
        </Link>
        |<p className="ml-1 text-base">{new Date().getFullYear()}</p>
      </div>
      <div className="order-first flex flex-row gap-2 md:order-last">
        <IconLink
          icon={<BsGithub size={16} />}
          href="https://github.com/MontgomeryBohde"
        />
        <IconLink
          icon={<BsLinkedin size={16} />}
          href="https://www.linkedin.com/in/montgomery-bohde/"
        />
        <IconLink
          icon={<BsEnvelopeFill size={16} />}
          href="mailto:montgomerybohde@gmail.com"
        />
      </div>
    </div>
  );
};

export default Footer;

import React from "react";
import Hero from "../components/Hero";
import Showcase from "../components/Showcase";
import PublicationCardShowcase from "../components/PublicationCardShowcase";
import ProjectCardShowcase from "../components/ProjectCardShowcase";

const Home = () => {
  return (
    <div>
      <Hero />
      <div className="flex flex-col px-8 md:px-16 lg:px-24 xl:px-48 fhd:mx-auto fhd:w-full fhd:max-w-screen-2xl fhd:px-0">
        <Showcase />
        <PublicationCardShowcase />
        <ProjectCardShowcase /> 
      </div>
    </div>
  );
};

export default Home;

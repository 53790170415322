import { publications } from "../data/PublicationList";
import PublicationCard from "./PublicationCard";

const PublicationCardShowcase = () => {
  return (
    <div className="flex flex-col justify-between py-5 lg:py-10 border-y-8 border-white dark:border-zinc-900">
      <h2 className="text-3xl font-bold">Publications 📑</h2>
      <div className="mt-2 w-full grid grid-cols-1 gap-4 lg:mt-4 lg:gap-8">
        {publications
          .slice(0)
          .sort((a, b) => a.id - b.id)
          .slice(0, 3)
          .map((publication) => (
            <PublicationCard key={publication.id} publication={publication} />
          ))}
      </div>
    </div>
  );
};

export default PublicationCardShowcase;
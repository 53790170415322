import { Publication } from "./Publication";

export const publications: Publication[] = [
  {
    id: 1,
    title: "On the Markov Property of Neural Algorithmic Reasoning: Analyses and Methods",
    authors: ["Montgomery Bohde", "Meng Liu", "Alexandra Saxton", "Shuiwang Ji"],
    conference: "Preprint",
    paper_link: "",
    code_link: "",
    image: "",
  },
  {
    id: 2,
    title: "Equivariant Graph Network Approximations of High-Degree Polynomials for Force Field Prediction",
    authors: ["Zhao Xu, Haiyang Yu, Montgomery Bohde, Shuiwang Ji"],
    conference: "Preprint",
    paper_link: "",
    code_link: "",
    image: "",
  },
  {
    id: 3,
    title: "Artificial Intelligence for Science in Quantum, Atomistic, and Continuum Systems",
    authors: ["Xuan Zhang", "Limei Wang", "Jacob Helwig", "Youzhi Luo", "Cong Fu", "Yaochen Xie", "Meng Liu",
              "Yuchao Lin", "Zhao Xu", "Keqiang Yan", "Keir Adams", "Maurice Weiler", "Xiner Li", "Tianfan Fu",
              "Yucheng Wang", "Haiyang Yu", "YuQing Xie", "Xiang Fu", "Alex Strasser", "Shenglong Xu", "Yi Liu",
              "Yuanqi Du", "Alexandra Saxton", "Hongyi Ling", "Hannah Lawrence", "Hannes Stärk", "Shurui Gui", 
              "Carl Edwards", "Nicholas Gao", "Adriana Ladera", "Tailin Wu", "Elyssa F. Hofgard", "Aria Mansouri Tehrani",
              "Rui Wang", "Ameya Daigavane", "Montgomery Bohde", "Jerry Kurtin", "Qian Huang", "Tuong Phung", "Minkai Xu",
              "Chaitanya K. Joshi", "Simon V. Mathis", "Kamyar Azizzadenesheli", "Ada Fang", "Alán Aspuru-Guzik", 
              "Erik Bekkers", "Michael Bronstein", "Marinka Zitnik", "Anima Anandkumar", "Stefano Ermon", "Pietro Liò",
              "Rose Yu", "Stephan Günnemann", "Jure Leskovec", "Heng Ji", "Jimeng Sun", "Regina Barzilay", 
              "Tommi Jaakkola", "Connor W. Coley", "Xiaoning Qian", "Xiaofeng Qian", "Tess Smidt", "Shuiwang Ji"
  ],
    conference: "Preprint",
    paper_link: "https://arxiv.org/abs/2307.08423",
    code_link: "https://github.com/divelab/AIRS",
    image: "",
  },
];

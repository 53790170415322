import React from "react";
import { Project } from "../data/Project";

interface ProjectCardProps {
  project: Project;
}

const ProjectCard = ({ project }: ProjectCardProps) => {
  return (
      <div className="align-center group flex h-48 w-full flex-col justify-between gap-4 overflow-hidden rounded-xl bg-zinc-100 px-6 py-6 dark:bg-zinc-800 md:flex-row md:pl-8 md:py-8">
        <div className="align-start flex flex-1 flex-col justify-between">
          <div className="flex flex-col gap-2">
            <p className="text-xs tracking-wider">{`${project.year}${
              project.current ? " - PRESENT" : ""
            }`}</p>
            <h3 className="text-xl font-semibold">{project.title}</h3>
            <p className="text-sm">{project.description}</p>
          </div>
        </div>
        <img
          src={project.image}
          alt={project.image}
          className="w-96 flex-1 translate-x-16 object-contain md:translate-x-5"
        />
      </div>
  );
};

export default ProjectCard;

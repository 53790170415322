import React, { ReactNode } from "react";

interface IconLinkProps {
  icon: ReactNode;
  href: string;
}

const IconLink = ({ icon, href }: IconLinkProps) => {
  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      className="transition duration-200 ease-in-out hover:opacity-70"
    >
      {icon}
    </a>
  );
};

export default IconLink;

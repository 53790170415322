import React, { useEffect } from "react";
import SectionReturnLink from "../components/SectionReturnLink";

const NotFound = () => {
  useEffect(() => {
    document.title = "not found";
  }, []);

  return (
    <div className="flex h-[calc(100vh-92px)] flex-col items-start justify-center gap-4 px-8 md:h-[calc(100vh-64px)] md:px-16 lg:px-24 xl:px-48 fhd:mx-auto fhd:w-full fhd:max-w-screen-2xl fhd:px-0">
      <h1 className="text-8xl font-semibold">404</h1>
      <h2 className="text-3xl font-semibold md:text-4xl">page not found</h2>
      <div className="mt-8 flex">
        <SectionReturnLink heading="home" href="" />
      </div>
    </div>
  );
};

export default NotFound;

import React, { useContext } from "react";
import ThemeContext from "../context/ThemeContext";

const ThemeToggle = () => {
  const { currentTheme, changeCurrentTheme } = useContext(ThemeContext);

  return (
    <label className="relative h-6 w-10">
      <input
        id="toggle"
        type="checkbox"
        className="h-0 w-0 opacity-0"
        onClick={() =>
          changeCurrentTheme(currentTheme === "light" ? "dark" : "light")
        }
      />
      <span
        className={`absolute top-0 left-0 right-0 bottom-0 cursor-pointer rounded-4xl transition duration-300 ${
          currentTheme === "dark" ? "bg-white" : "bg-zinc-800"
        }`}
      ></span>
      <span
        className={`absolute left-1 bottom-1 h-4 w-4 cursor-pointer rounded-half transition duration-300 ${
          currentTheme === "dark" ? "translate-x-4 bg-zinc-800" : "bg-white"
        }`}
      ></span>
    </label>
  );
};

export default ThemeToggle;

import React from "react";
import {
  BsGithub,
  BsLinkedin,
} from "react-icons/bs";
import IconLink from "../common/IconLink";

const Hero = () => {
  return (
    <div className="flex h-screen flex-col items-start justify-center rounded-2xl border-8 border-white bg-zinc-100 px-8 py-5 dark:border-zinc-900 dark:bg-zinc-800 md:box-border md:rounded-6xl md:border-32 md:border-solid md:px-8 lg:px-16 lg:py-10 xl:rounded-10xl xl:border-64 xl:px-32">
      <div className="flex h-screen w-full flex-col items-start justify-center fhd:mx-auto fhd:max-w-screen-2xl">
        <div className="flex w-full flex-6 items-center justify-start">
          <h1 className="text-6xl font-semibold md:text-8xl">Montgomery Bohde</h1>
        </div>
        <div className="mb-20 flex w-full flex-1 flex-col items-start justify-between md:mb-0 md:flex-row md:items-center">
          <div className="flex flex-col justify-start md:flex-row md:items-center md:gap-4">
            <div className="flex flex-row items-center gap-4">
              <IconLink
                icon={<BsGithub size={32} />}
                href="https://github.com/MontgomeryBohde"
              />
              <IconLink
                icon={<BsLinkedin size={32} />}
                href="https://www.linkedin.com/in/montgomery-bohde/"
              />
            </div>
            <div className="mt-2 md:mt-0">
              <p>montgomerybohde[at]gmail.com</p>
            </div>
          </div>
          <div className="mt-4 flex flex-row items-center justify-center gap-2 md:mt-0">
            <p>scroll</p>
            <span className="animate-bounce">↓</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;

import React from "react";
import AboutImg from "../assets/about/about-image.jpg";
import InlineLink from "../common/InlineLink";

const AboutShowcase = () => {
  return (
    <div className="flex flex-col items-center justify-between lg:flex-row">
      <div className="flex flex-2 flex-col md:w-full md:flex-row md:justify-start">
        <img
          src={AboutImg}
          alt="AboutImg"
          className="w-full rounded-xl md:w-56 xl:w-72"
        />
        <div className="align-start flex flex-col justify-center gap-2 md:ml-6 xl:ml-8 xl:gap-4">
          <h3 className="text-xl font-semibold">
            Hi, I'm Montgomery! 👋
          </h3>
          <div>
            <h3 className="font-semibold">Currently...</h3>
            <p>
              Studying CS + Math{" "}
              <InlineLink href="https://www.tamu.edu/">@TAMU</InlineLink> <br />{" "}
              Machine Learning Researcher{" "}
              <InlineLink href="https://github.com/divelab/">@DIVE</InlineLink>
            </p>
          </div>
          <div>
            <h3 className="font-semibold">Interested in...</h3>
            <p>
              Deep Learning
              <br />
              Graph Neural Networks
              <br />
              Data Engineering
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutShowcase;

import { Project } from "./Project";
import ocp_image from "../assets/projects/ocp_image.png";
import datathon2023_image from "../assets/projects/datathon2023.jpg";
import hackharv from "../assets/projects/hackharv.jpg";
import glaid from "../assets/projects/glaid.jpg";
import mathlite from "../assets/projects/mathlite.png";

export const projects: Project[] = [
  {
    id: 2,
    month: "11",
    year: "2023",
    current: false,
    title: "Open Catalyst Project",
    description: "Developed SE(3)-Invariant Graph Neural Networks to predict energy, forces, and relaxed atomic structures of catalyst systems",
    image: ocp_image,
  },
  {
    id: 1,
    month: "10",
    year: "2023",
    current: false,
    title: "TAMU Datathon 2023",
    description: "Finetuned ImageNet pretrained models to classify handrawn images into different categories",
    image: datathon2023_image,
  },
  {
    id: 4,
    month: "10",
    year: "2023",
    current: false,
    title: "Drifts.space",
    description: "Built a service that constructs optimal satellite constellations to provide emergency support during global disasters",
    image: hackharv,
  },
  {
    id: 5,
    month: "10",
    year: "2023",
    current: false,
    title: "Glaid",
    description: "Built a website that uses LLMs to generates study materials (quizzes, flashcards) based on users uploaded course materials",
    image: glaid,
  },
  {
    id: 3,
    month: "10",
    year: "2023",
    current: false,
    title: "MathLite",
    description: "Built an open-source C++ Library for Linear Algebra and Calculus, and created numerical approximation algorithms",
    image: mathlite,
  },
];

import React from "react";
import { Publication } from "../data/Publication";

interface PublicationCardProps {
  publication: Publication;
}

const PublicationCard = ({ publication }: PublicationCardProps) => {
  const authors = publication.authors.join(", ").split(" ");

  return (
    <div className="align-center group flex w-full flex-col justify-between gap-1 rounded-xl bg-zinc-100 px-4 py-4 dark:bg-zinc-800 md:px-6 md:py-6 relative">
      <div>
        <h3 className="text-xl font-semibold flex items-start justify-start">{publication.title}</h3>
      </div>
      <div className="text-sm">
        <p>
          {authors.map((word, index) => (
            <span key={index} style={{ fontWeight: word.replace(",", "") === "Montgomery" || word.replace(",", "") === "Bohde" ? "bold" : "normal" }}>
              {word + (index < authors.length - 1 ? " " : "")}
            </span>
          ))}
        </p>
      </div>
      {publication.image && (
        <div>
          <img src={publication.image} alt={publication.title} />
        </div>
      )}
      {publication.paper_link && (
        <div className="flex gap-2 mt-1">
          <a href={publication.paper_link} target="_blank" rel="noopener noreferrer">
            <button className="px-4 py-2 border-2 border-black hover:border-black hover:scale-105 transform transition duration-200 ease-in-out rounded">
              Paper
            </button>
          </a>
          {publication.code_link && (
            <a href={publication.code_link} target="_blank" rel="noopener noreferrer">
              <button className="px-4 py-2 border-2 border-black hover:border-black hover:scale-105 transform transition duration-200 ease-in-out rounded">
                Code
              </button>
            </a>
          )}
        </div>
      )}
    </div>
  );
};

export default PublicationCard;